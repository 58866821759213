import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { UserRoles } from '../Models/user';
import { AuthCommonService } from 'src/app/shared/Authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService  {
  readonly _administratorsApiBaseURl:any;

  constructor(private http: HttpClient,private authCommonService: AuthCommonService) {
    this._administratorsApiBaseURl=environment._administratorsApiBaseURl;
   }

   GetUserCodeList(type:number){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._administratorsApiBaseURl + '/User/GetCodeList?type='+type, httpOptions);
   
  }

  GetUserList(){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
    return this.http.get<any>(this._administratorsApiBaseURl + '/User/GetAllUsers', httpOptions);
   
  }

  GetUserViewDetailsById(userId:number){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.get<any>(this._administratorsApiBaseURl + '/User/GetUserView?userId='+userId, httpOptions);
  }

  GetUserEidtDetailsById(userId:number){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'ApiKey':this.authCommonService.getAuthenticationToken()}) };
    return this.http.get<any>(this._administratorsApiBaseURl + '/User/GetUserEditDetails?userId='+userId, httpOptions);
  }

  UpdateUserRoles(UserRolesData: UserRoles): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.put<any>(this._administratorsApiBaseURl + '/User/UpdateUserRoles', UserRolesData, httpOptions);
  }
  UserPrivilegesByEmail(userObj:any){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.put<any>(this._administratorsApiBaseURl + '/User/UserPrivilegesByEmail', userObj, httpOptions);
  }

  UpdateUserGroup(usergroup:any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.put<any>(this._administratorsApiBaseURl + '/User/UpdateUserGroups', usergroup, httpOptions);
  }


  SyncUserListWithAdminCenter(admincenterUserList:any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','ApiKey':this.authCommonService.getAuthenticationToken() }) };
    return this.http.put<any>(this._administratorsApiBaseURl + '/User/SyncUserListWithAdminCenter', admincenterUserList, httpOptions);
  }

}
