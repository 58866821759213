<div class="card flex justify-content-center">
   <p-breadcrumb class="max-w-full" [model]="getBreadcrumbs()"></p-breadcrumb>
 </div>
<div class="container page-title"> 
  <div class="flex-box-inside-pages"> 
    <div class="fr-flex__container">
        <div class="fr-flex__col-8">
            <h1>View User Roles Mapping</h1>
        </div>
    </div>
   </div>
      <p-tabView class="fr-tabview__header__custom"> 
         <p-tabPanel> 
            <div class="card-pad"> 
    <div class="fr-flex__container">
      <div class="fr-flex__col-4"> </div>
      <div class="fr-flex__col-8">
         <select class="form-control selectpicker pull-right" [(ngModel)]="selectedOption"  (change)="onOptionChange()">
            <option> Actions </option>
            <option *ngIf="editPrivilege"> Edit </option>
            
         </select>
          <div class="preview_section pull-right">
               <button  [class.previous]="currentIndex !== 0" (click)="previousRecord()" [disabled]="currentIndex === 0"><i class="pi pi-arrow-up" pTooltip="Preview" tooltipPosition="top"></i></button>
               <span class="numbers_list">{{getCurrentPositionText()}}</span>
               <button [class.next]="currentIndex !== userData.length - 1" (click)="nextRecord()" [disabled]="currentIndex === userData.length - 1"><i class="pi pi-arrow-down" pTooltip="Next" tooltipPosition="top"></i></button>
               </div> 
            <a class="btn btn-primary-link backtolist pull-right" (click)="BackToList()">  <i class="pi pi-arrow-left"></i> Back to list </a>
      </div>
    </div>
<div class="fr-flex__container m-t-20">
    <div class="fr-flex__col-4">
       <div class="fr-form__view">
          <label> First Name </label>
          <span [pTooltip]="user?.firstName!.length > 30 ? user?.firstName! : ''" tooltipPosition="top"> <em> {{user?.firstName ? user?.firstName : '--'}}  </em></span>
       </div>
    </div>
    <div class="fr-flex__col-4">
       <div class="fr-form__view">
          <label> Last Name  </label>
          <span [pTooltip]="user?.lastName!.length > 30 ? user?.lastName! : ''" tooltipPosition="top"> <em> {{user?.lastName ? user?.lastName : ''}}  </em></span>
       </div>
    </div>
    <div class="fr-flex__col-4">
        <div class="fr-form__view">
           <label> Email  </label>
           <span [pTooltip]="user?.emailId!.length > 30 ? user?.emailId! : ''" tooltipPosition="top"> <em> {{user?.emailId ? user?.emailId : '--'}}   </em></span>
        </div>
     </div>
 </div>
 <div class="fr-flex__container">
   <div class="fr-flex__col-4">
      <div class="fr-form__view">
         <label> User Name  </label>
         <span [pTooltip]="user?.userName!.length > 30 ? user.userName! : ''" tooltipPosition="top"> <em> {{user?.userName ? user.userName : '--'}}  </em></span>
      </div>
   </div>
    <div class="fr-flex__col-4">
       <div class="fr-form__view">
          <label> Group Type <em> * </em> </label>
          <span [pTooltip]="user?.groupType!.length > 30 ? user.groupType! : ''" tooltipPosition="top"> <em> {{user?.groupType ? user.groupType : '--'}}   </em></span>
       </div>
    </div>
    <div class="fr-flex__col-4">
      <div class="fr-form__view">
         <label> Group <em> * </em> </label>
         <span [pTooltip]="user?.group!.length > 30 ? user.group! : ''" tooltipPosition="top"> <em> {{user?.group ? user.group : '--'}}   </em></span>
      </div>
   </div>
   
   
 </div>
 <div class="fr-flex__container">
   <div class="fr-flex__col-4">
      <div class="fr-form__view">
         <label> Assigned Role(s) <em> * </em> </label>
         <span [pTooltip]="user?.assignedRoles!.length > 30 ? user.assignedRoles! : ''" tooltipPosition="top"> <em>  {{user?.assignedRoles ? user.assignedRoles : '--' }}   </em></span>
      </div>
   </div>
    
   <div class="fr-flex__col-4">
      <div class="fr-form__view">
      <label> IsActive </label>
      <span><em>{{isUserActive}}</em></span>
   </div>
   </div>


   <div class="fr-flex__col-4">
      <div class="fr-form__view">
      <label> IsLocked </label>
      <span><em>{{isUserLocked}}</em></span>
   </div>
   </div>


   
 </div>
 <div class="fr-flex__container"> 
 

   <div class="fr-flex__col-12">
   <div class="fr-form__view">
      <label> Remarks </label>
      <span class="full-width" *ngIf="remarks  == null">--</span> <span class="full-width" [innerHTML]="remarks" *ngIf="remarks  != null"> {{remarks }} </span>
   </div>
</div>

  



</div>
 <div class="fr-flex__container">

   <div class="fr-flex__col-4">
      <div class="fr-form__view">
         <label> Modified By</label>
         <span id="ViewUserRoleModiBy"> <em> {{updatedByUserName!=''?updatedByUserName:'--'}}</em></span>

      </div>
   </div>
   <div class="fr-flex__col-4">
      <div class="fr-form__view">
         <label> Modified Date & Time </label>
         <span id="ViewUserRoleModiDateTime" *ngIf="updatedByUserName!=''"> <em> {{user?.updatedDate | date: _dateformart.DateTimeWithZone }} {{_dateformart.DisplayUTC}}</em></span>
         <span id="ViewUserRoleHypen" *ngIf="updatedByUserName==''"> <em> -- </em></span>
        </div>
   </div>
</div>
</div>
</p-tabPanel>
</p-tabView>
</div>

