<!DOCTYPE html>
<html lang="en">
<head>
    <title> Engagement View KPI</title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
<div class="card-pad">
    <div class="fr-flex__container sticky-sub-header">
        <div class="fr-flex__col-4">
            <h3>View KPI</h3>
        </div>
        <div class="fr-flex__col-8">
           <select class="form-control selectpicker pull-right"  [(ngModel)]="selectedOption" (change)="onOptionChange()">
                <option disabled selected > Actions </option>
                <option *ngIf="editPrivilege"> Edit </option>
                <option *ngIf="deletePrivilege"> Delete </option>
            </select> 
            <div class="preview_section pull-right" *ngIf="currentIndex!==-1">
                <button  [class.previous]="currentIndex !== 0" (click)="previousRecord()" [disabled]="currentIndex === 0"><i class="pi pi-arrow-up" pTooltip="Preview" tooltipPosition="top"></i></button>
                <span class="numbers_list">{{getCurrentPositionText()}}</span>
                <button [class.next]="currentIndex !== listOfKpis.length - 1" (click)="nextRecord()" [disabled]="currentIndex === listOfKpis.length - 1"><i class="pi pi-arrow-down" pTooltip="Next" tooltipPosition="top"></i></button>
                </div> 
            <a class="btn btn-primary-link backtolist pull-right" (click)="BackToList()">  <i class="pi pi-arrow-left"></i> Back to list </a>
        </div>
    </div>
    <div class="scroll-start-here-sub-tabs">
                        <div class="fr-flex__container">
                            <div class="fr-flex__col-4">
                                <div class="fr-form__view">
                                    <label> KPI <em> * </em></label>
                                   <span> <em> {{_kpiDetails.kpiName}}</em></span> 
                                </div>
                            </div> 
                            
                           <div class="fr-flex__col-4">
                                <div class="fr-form__view">
                                    <label> Service <em> * </em></label>
                                   <span [pTooltip]="_viewEngagementKPIDetails.serviceName!.length > 30 ? _viewEngagementKPIDetails.serviceName! : ''"
                                    tooltipPosition="top"> <em> {{_viewEngagementKPIDetails.serviceName}}</em></span> 
                                </div>
                            </div>  


                            <div class="fr-flex__col-4">
                                <div class="fr-form__view">
                                    <label> KPI Name<em> * </em></label>
                                   <span [pTooltip]="_viewEngagementKPIDetails.kpiName!.length > 30 ? _viewEngagementKPIDetails.kpiName! : ''"
                                    tooltipPosition="top"> <em> {{_viewEngagementKPIDetails.kpiName}}</em></span> 
                                </div>
                            </div>

                          
                     
        
                        </div>

                       
                        <div class="fr-flex__col-12">
                            <div class="m-b-20">
                                <span class="table-small-card"> Current Health </span>
                                <span id="engViewKPIHealthInformation" class="table-small-card"> {{_kpiDetails.unitOfMeasureCode=='%'?_viewEngagementKPIDetails?.overAllAverageKPI == null?'--': _viewEngagementKPIDetails?.overAllAverageKPI+_kpiDetails.unitOfMeasureCode:_viewEngagementKPIDetails?.overAllAverageKPI == null?'--': _viewEngagementKPIDetails?.overAllAverageKPI+ ' ' + _kpiDetails.unitOfMeasureCode}} 
                                    <span [ngClass]="{
                                        'success__circle': _viewEngagementKPIDetails?.overallAverageHealth === 'Green',
                                        'danger__circle': _viewEngagementKPIDetails?.overallAverageHealth === 'Red'
                                      }"></span>
                            </span> 
                             </div>
                        <div class="m-b-30"> 
                            <p-table id="engviewKPItableName" class="primeng-table-custom custom-cells__contractattributeview" [resizableColumns]="true" columnResizeMode="expand" styleClass="p-datatable-striped p-datatable-gridlines" [scrollable]="true" [value]="kpiReportDataCollection" [tableStyle]="{ 'min-width': '50rem' }">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th id="engViewKpiDataFor" pResizableColumn>Data For </th>
                                        <th id="engViewKpiAttributeOne" pResizableColumn> Attribute 1 <br> <span [pTooltip]="_viewEngagementKPIDetails?.attribute1?.length > 10 ? _viewEngagementKPIDetails?.attribute1 : ''" tooltipPosition="top">  ({{_viewEngagementKPIDetails.attribute1 == ""?"--":_viewEngagementKPIDetails.attribute1}}) </span></th>
                                        <th id="engViewKpiAttributeTwo" pResizableColumn>Attribute 2  <br> <span [pTooltip]="_viewEngagementKPIDetails?.attribute2?.length > 10 ? _viewEngagementKPIDetails?.attribute2 : ''" tooltipPosition="top"> ({{_viewEngagementKPIDetails.attribute2 == ""?"--":_viewEngagementKPIDetails.attribute2}}) </span></th>
                                        <th id="engViewKpiAttributeThree" pResizableColumn>Attribute 3 <br> <span [pTooltip]="_viewEngagementKPIDetails?.attribute3?.length > 10 ? _viewEngagementKPIDetails?.attribute3 : ''" tooltipPosition="top"> ({{_viewEngagementKPIDetails.attribute3 == ""?"--":_viewEngagementKPIDetails.attribute3}}) </span></th>
                                        <th id="engViewKpiCalculatedKPI" pResizableColumn>Calculated KPI </th>
                                        <th id="engViewKpiActualDateFillDate" pResizableColumn >Actual Data Fill Date </th>
                                        <th id="engViewKpiDataFilledBy" pResizableColumn>Data Filled By </th>
                                        <th id="engViewKpiRemarks" pResizableColumn>Remarks </th>
                                        <th id="engViewKpiMitigationActions" pResizableColumn>Mitigation Actions </th>
                                        <th id="engViewKpiHealth" class="last-child-width"> Health </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-kpiReportDataCollection let-i="index">
                                    <tr class="bg" *ngIf="_viewEngagementKPIDetails.kpiDataCollectionFrequencyName !=_viewEngagementKPIDetails.kpiReportingFrequencyName">
                                        <td class="text-center" colspan="4">{{kpiReportDataCollection.name}}</td>
                                        <td class="weight500" colspan="5">{{_kpiDetails.unitOfMeasureCode=='%'?kpiReportDataCollection.avearageCalculatedKPIForReport ==null? "--":kpiReportDataCollection.avearageCalculatedKPIForReport+_kpiDetails.unitOfMeasureCode:kpiReportDataCollection.avearageCalculatedKPIForReport ==null? "--":kpiReportDataCollection.avearageCalculatedKPIForReport+" "+_kpiDetails.unitOfMeasureCode}}</td>
                                        <td class="circle_center">
                                            <span class="success__circle" *ngIf="kpiReportDataCollection?.healthForReport === 'Green'"></span>
                                            <span class="danger__circle" *ngIf="kpiReportDataCollection?.healthForReport === 'Red'"></span>
                                          </td>    
                                    </tr>
                                
                                
                                    <ng-container *ngFor="let dataCollectionItem of kpiReportDataCollection.kpiDataCollectionModelList; let i = index">
                               
                                    <tr *ngIf="dataCollectionItem.inputAttributeValueType !== 3">
                                        <td>{{ dataCollectionItem.dataCollectionFrequencyValue}}</td>
                                        <td class="text-center">{{(dataCollectionItem.attribute1==null||dataCollectionItem.attribute1=="")? "--": getFormattedValue(dataCollectionItem.attribute1,currencies)}}</td>
                                        <td class="text-center">{{ (dataCollectionItem.attribute2 ==null|| dataCollectionItem.attribute2 =="")? "--": getFormattedValue(dataCollectionItem.attribute2,currencies)}}</td>
                                        <td class="text-center">{{ (dataCollectionItem.attribute3 ==null|| dataCollectionItem.attribute3 =="")? "--":getFormattedValue(dataCollectionItem.attribute3,currencies)}}</td>
                                        <td class="">{{ _kpiDetails.unitOfMeasureCode=='%'?dataCollectionItem.calculatedKPI ==null? "--": dataCollectionItem.calculatedKPI+_kpiDetails.unitOfMeasureCode:dataCollectionItem.calculatedKPI ==null? "--": dataCollectionItem.calculatedKPI+" "+_kpiDetails.unitOfMeasureCode}}</td>
                                        <td class="text-center">{{ dataCollectionItem.remarks ==null? "--": dataCollectionItem.updatedDate | date: _dateformart.GridDateFormat }}</td>
                                        <td class="text-center">{{ dataCollectionItem.remarks ==null? "--": privilegeService.getUserName(dataCollectionItem.createdBy) }}</td>
                                      	    
                                        <td class="text-center">
                                            <span pInputText pTooltip="{{ dataCollectionItem.remarks == '' ? '--' : dataCollectionItem.remarks }}" tooltipPosition="top" class="pi pi-info-circle"></span>
                                        </td>  
                                                  <td class="text-center">
                                                    <a class="table-active-link" (click)="viewFindingList(i,kpiReportDataCollection)">
                                                      {{ getMitigationActionCount(dataCollectionItem.id) > 0 ? getMitigationActionCount(dataCollectionItem.id) : '0' }}
                                                    </a>
                                                  </td>
                                             
                                          <td class="grid-icon-center">
                                            <ng-container *ngIf="dataCollectionItem?.health; else noHealth">
                                              <span class="success__circle" *ngIf="dataCollectionItem?.health === 'Green'"></span>
                                              <span class="danger__circle" *ngIf="dataCollectionItem?.health === 'Red'"></span>
                                            </ng-container>
                                            <ng-template #noHealth>--</ng-template>
                                        </td>
                                    </tr>
                                        
                                    <tr *ngIf="dataCollectionItem.inputAttributeValueType === 3">
                                            
                                            <td>{{ dataCollectionItem.dataCollectionFrequencyValue}}</td>
                                            <td class="text-center">NV</td>
                                            <td class="text-center">NV</td>
                                            <td class="text-center">NV</td>
                                            <td class="">NV</td>
                                            <td class="text-center">{{ dataCollectionItem.remarks ==null? "--": dataCollectionItem.updatedDate | date: _dateformart.GridDateFormat }}</td>
                                            <td class="text-center">{{ dataCollectionItem.remarks ==null? "--": privilegeService.getUserName(dataCollectionItem.createdBy) }}</td>
                                                  
                                            <td class="text-center">
                                                <span pInputText pTooltip="{{ dataCollectionItem.remarks == '' ? '--' : dataCollectionItem.remarks }}" tooltipPosition="top" class="pi pi-info-circle"></span>
                                            </td>  
                                                      <td class="text-center">
                                                        <a class="table-active-link" (click)="viewFindingList(i,kpiReportDataCollection)">
                                                          {{ getMitigationActionCount(dataCollectionItem.id) > 0 ? getMitigationActionCount(dataCollectionItem.id) : '0' }}
                                                        </a>
                                                      </td>
                                                 
                                              <td class="grid-icon-center">
                                                NV
                                              </td>
                                            </tr>
                                            
                                    </ng-container>

                                

                            </ng-template>
                            </p-table>
                       
                    </div>
                    </div>
                       

<div class="fr-flex__container m-t-20">
    <div class="fr-flex__col-4">
        <div class="fr-form__view">
            <label> Data Collection Frequency <em> * </em></label>
            <span> <em> {{_viewEngagementKPIDetails.kpiDataCollectionFrequencyName}}  </em></span> 
        </div>
    </div>
    <div class="fr-flex__col-4">
        <div class="fr-form__view">
            <label> KPI Reporting Frequency <em> * </em> <i  pInputText pTooltip="Reporting Frequency can only be selected after Data Collection Frequency is selected." tooltipPosition="top" class="pi pi-info-circle"> </i></label>
           <span> <em> {{_viewEngagementKPIDetails.kpiReportingFrequencyName}}  </em></span> 
        </div>
    </div>
</div>

                        <div class="fr-flex__container">
                            <div class="fr-flex__col-4">
                                <div class="fr-form__view">
                                    <label> KPI Tracking  Start <em> * </em></label>
                                
                                <span> <em> {{kpiStartFrequency}} </em></span> 
                                </div>
                            </div>
                            <div class="fr-flex__col-4">
                                <div class="fr-form__view">
                                    <label> KPI Tracking  End <em> * </em></label>
                                 
                                 <span> <em>  {{kpiEndFrequency}} </em></span> 
                                </div>
                            </div>
                            <div class="fr-flex__col-4">
                                <div class="fr-form__view">
                                    <label> Status <em> * </em></label>
                                <span> <em> {{_viewEngagementKPIDetails?.status }}  </em></span> 
                                </div>
                            </div>
                        </div>
                        <div class="fr-flex__container">
                            <div class="fr-flex__col-4">
                                <div class="fr-form__view">
                                    <label> Unit of Measure </label>
                             <span> <em> {{_kpiDetails.unitOfMeasure}}  </em></span>
                                </div>
                            </div>                                                   
                            <div class="fr-flex__col-4">
                                <div class="fr-form__view">
                                    <label>Hyper Care Target <em> * </em></label>
                                    <ng-container *ngIf="_viewEngagementKPIHyperCareThresholdDetails.length == 0; else dataBlock">
                                    <div class="display-flex m-t-10">
                                        
                                        <p-checkbox class="kpi-checkbox__alignment"  [binary]="true" inputId="binary" [(ngModel)]="checkBoxValue" [disabled]="true"> </p-checkbox>
                                        <label for="binary">Not Applicable</label>
                                      </div>
                                    </ng-container>                                
                                    <ng-template #dataBlock>                                      
                                        <ng-container *ngFor="let _hyperCareThreshold of _viewEngagementKPIHyperCareThresholdDetails; let i = index">
                                            <span><em>{{_hyperCareThreshold.mathematicalSymbol}}&nbsp;</em></span>
                                            <span><em>{{_hyperCareThreshold.percentageValue}}</em></span>
                                            <span class="m-r-10"><em>{{_kpiDetails.unitOfMeasureCode}} </em></span>
                                        </ng-container>
                                    </ng-template>
                                    
                                </div>
                            </div>
                            
        
                        </div>
                        <div class="fr-flex__container">
                            <div class="fr-flex__col-4">
                                <div class="fr-form__view">
                                    <label> Hyper Care Start Date <em> * </em> </label>                                  
                                      <span *ngIf="_viewEngagementKPIDetails?.hyperCareStartDate !== null">                                       
                                       <em>  {{ _viewEngagementKPIDetails?.hyperCareStartDate | date: _dateformart.GridDateFormat }}  </em>                                     
                                      </span>
                                      <span *ngIf="_viewEngagementKPIDetails?.hyperCareStartDate === null"> <em> -- </em></span>
                                </div>
                            </div> 
                            <div class="fr-flex__col-4">
                                <div class="fr-form__view">
                                   <label> Hyper Care End Date <em> * </em> </label>                                                           
                                      <span *ngIf="_viewEngagementKPIDetails?.hyperCareEndDate !== null">                                       
                                        <em>  {{ _viewEngagementKPIDetails?.hyperCareEndDate | date: _dateformart.GridDateFormat }} </em>                                      
                                      </span>
                                      <span *ngIf="_viewEngagementKPIDetails?.hyperCareEndDate === null"> <em> -- </em>  </span>
                                </div>
                            </div>                           
                            <div class="fr-flex__col-4">
                                <div class="fr-form__view">
                                    <label>Steady State Target <em> * </em></label>
                                    <ng-container *ngFor="let _steadyStateCareThresholdDetails of _viewEngagementKPIteadyStedyStateThresholdDetails; let i = index">                               
                                        <span><em>{{_steadyStateCareThresholdDetails.mathematicalSymbol}}&nbsp;</em></span>
                                        <span><em>{{_steadyStateCareThresholdDetails.percentageValue}}</em></span>
                                        <span class="m-r-10"><em>{{_kpiDetails.unitOfMeasureCode}}</em></span>
                                    </ng-container>
                                </div>
                            </div>
                            
                        </div>
                       
                        <div class="fr-flex__container">
                            <div class="fr-flex__col-4">
                                <div class="fr-form__view">
                                    <label> Category </label>
                                  <span> <em>  {{ _kpiDetails.category}}  </em></span>
                                </div>
                            </div>
                            <div class="fr-flex__col-4">
                                <div class="fr-form__view">
                                    <label> Compliance Indicator  </label>
                                    <span> <em> {{_kpiDetails.complianceIndicator}} </em></span> 
                                </div>
                            </div>
                            <div class="fr-flex__col-4">
                                <div class="fr-form__view">
                                    <label> Calculation </label>
                                     <span [pTooltip]="_viewEngagementKPIDetails.calculation.length > 38 ? _viewEngagementKPIDetails.calculation : ''" tooltipPosition="top"> <em>  {{_viewEngagementKPIDetails.calculation}} </em></span> 
                                </div>
                            </div>
                            
        
                        </div>
                        <div class="fr-flex__container">
                            <div class="fr-flex__col-4">
                                <div class="fr-form__view">
                                    <label> Attribute 1 </label>
                                   <span [pTooltip]="_viewEngagementKPIDetails.attribute1.length > 35 ? _viewEngagementKPIDetails.attribute1 : ''" tooltipPosition="top"> <em> {{_viewEngagementKPIDetails.attribute1 ? _viewEngagementKPIDetails.attribute1 : '--'}}  </em></span> 
                                </div>
                            </div>

                            <div class="fr-flex__col-4">
                                <div class="fr-form__view">
                                    <label> Attribute 2 </label>
                                    <span [pTooltip]="_viewEngagementKPIDetails.attribute2.length > 35 ? _viewEngagementKPIDetails.attribute2 : ''" tooltipPosition="top"> <em>  {{_viewEngagementKPIDetails.attribute2 ? _viewEngagementKPIDetails.attribute2 : '--'}} </em></span>
                                </div>
                            </div>
                            <div class="fr-flex__col-4">
                                <div class="fr-form__view">
                                    <label> Attribute 3 </label>
                                   <span [pTooltip]="_viewEngagementKPIDetails.attribute3.length > 20 ? _viewEngagementKPIDetails.attribute3 : ''" tooltipPosition="top"> <em>  {{_viewEngagementKPIDetails.attribute3}} </em></span> 
                                </div>
                            </div>
                        </div>
                        
                        <div class="fr-flex__container">
                            <div class="fr-flex__col-12">
                                <div class="fr-form__view">
                                    <label> KPI Description </label>
                                    <span class="full-width" *ngIf="kpiDescription  == null || kpiDescription == '' ">--</span> <span class="full-width" [innerHTML]="kpiDescription" *ngIf="kpiDescription  != null"> {{kpiDescription }} </span>
                                </div>
                            </div>
                        </div>

                        <div class="fr-flex__container">
                            <div class="fr-flex__col-3">
                                <div class="fr-form__view">
                                    <label> Added By </label>
                                   <span> <em> {{kpiCreatedUserName}} </em></span> 
                                </div>
                            </div>
                            <div class="fr-flex__col-3">
                                <div class="fr-form__view">
                                    <label> Added Date & Time</label>
                                   
                                   <span> <em> {{_viewEngagementKPIDetails?.createdDate  | date: _dateformart.DateTimeWithZone}} {{_dateformart.DisplayUTC}} </em></span> 

                                  

                                </div>
                            </div>
                            
                            <div class="fr-flex__col-3">
                                <div class="fr-form__view">
                                    <label> Modified By </label>
                                  <span> <em> {{kpiUpdatedUserName!== '' ? kpiUpdatedUserName : '--'}}  </em></span> 
                                </div>
                            </div>
                            <div class="fr-flex__col-3">
                                <div class="fr-form__view">
                                    <label> Modified Date & Time </label>
                                    <span *ngIf="_viewEngagementKPIDetails?.updatedBy !== null">                                       
                                          
                                        <em> {{ _viewEngagementKPIDetails?.updatedDate  | date: _dateformart.DateTimeWithZone}} {{_dateformart.DisplayUTC}} </em>                                 
                                      </span>
                                      <span *ngIf="_viewEngagementKPIDetails?.updatedBy === null">--</span>
                                </div>
                            </div>
                            </div>

                            </div>
             <!-- Mitigation Action Popup Start -->

             <div class="card flex justify-content-center">
                <p-dialog appendTo="body" header="Mitigation Actions" [(visible)]="displayMitigationListPopup" [modal]="true" [style]="{ width: '80vw' }" [draggable]="false">
                  <table aria-describedby="engViewKPIMitigation" class="table-custom View_KPI_MitigationAction__Popup">
                    <thead>
                      <tr>
                        <th  id="engViewKPIDataForPopup">Data For</th>
                        <th id="engViewKPIAttributeOnePopup">Attribute 1 <br><span>({{ _viewEngagementKPIDetails.attribute1 == ""?"--":_viewEngagementKPIDetails.attribute1 }})</span></th>
                        <th id="engViewKPIAttributeTwoPopup">Attribute 2<br><span>({{ _viewEngagementKPIDetails.attribute2 == ""?"--":_viewEngagementKPIDetails.attribute2 }})</span></th>
                        <th id="engViewKPIAttributeThreePopup">Attribute 3<br><span>({{ _viewEngagementKPIDetails.attribute3 == ""?"--":_viewEngagementKPIDetails.attribute3 }})</span></th>
                        <th id="engViewKPICalculatedKPIPopup">Calculated KPI </th>
                        <th id="engViewKPIActualDataFillDatePopup">Actual Data Fill Date </th>
                        <th id="engViewKPIDataFilledByPopup">Data Filled By </th>
                        <th id="engViewKPIRemarksPopup">Remarks </th>
                        <th id="engViewKPIHealthPopup" class="last-child-width"> Health </th>  
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="selectedKpiReportData">
                          <td>{{selectedKpiReportData.dataCollectionFrequencyValue}}</td>
                          <td class="text-center">{{( selectedKpiReportData.attribute1==null|| selectedKpiReportData.attribute1=="")? "--":  selectedKpiReportData.attribute1}}</td>
                          <td class="text-center">{{( selectedKpiReportData.attribute2==null|| selectedKpiReportData.attribute2=="")? "--":  selectedKpiReportData.attribute2}}</td>
                          <td class="text-center">{{( selectedKpiReportData.attribute3==null|| selectedKpiReportData.attribute3=="")? "--":  selectedKpiReportData.attribute3}}</td>
                          <td>
                              {{ selectedKpiReportData.calculatedKPI || '--' }}
                              <span *ngIf="selectedKpiReportData.calculatedKPI">{{ _kpiDetails.unitOfMeasureCode }}</span>
                            </td>
                          <td>{{ selectedKpiReportData.remarks ==null? "--": selectedKpiReportData.updatedDate | date: _dateformart.GridDateFormat  }}</td>
                          <td>{{selectedKpiReportData.remarks ==null? "--": privilegeService.getUserName(selectedKpiReportData.createdBy)  }}</td>
                          <td>
                              <ng-container *ngIf="!selectedKpiReportData.remarks || selectedKpiReportData.remarks.length === 0; else showRemarks">
                                <i class="pi pi-info-circle"></i>
                              </ng-container>
                              <ng-template #showRemarks>
                                {{ selectedKpiReportData.remarks }}
                              </ng-template>
                            </td>
                       
                                <td class="grid-icon-center">
                                  <ng-container *ngIf="selectedKpiReportData?.health; else noHealth">
                                    <span class="success__circle" *ngIf="selectedKpiReportData?.health === 'Green'"></span>
                                    <span class="danger__circle" *ngIf="selectedKpiReportData?.health === 'Red'"></span>
                                  </ng-container>
                                  <ng-template #noHealth>--</ng-template>
                                </td>
                        </tr>
                      </tbody>
                    
                  </table>
                  <br><br>
                  <table aria-describedby="engagementViewKPIMitigationAction" class="table-custom View_Mitigation__alignTextPopup">
                    <thead>
                      <tr>
                          <th id="engViewKPIAction">Action</th>
                          <th id="engViewKPIActionOwner">Action Owner</th>
                          <th id="engViewKPITargetDate">Target Date</th>
                          <th id="engViewKPIActionStatus">Status</th>
                          <th id="engViewKPIClosureData">Closure Date</th>
                          </tr>
                          </thead>
                          <tbody>
                                <tr *ngFor="let action of mitigationActions">
                                  <td>{{action.action}}</td>
                                <td>{{getContactName(action.actionOwnerId)}}</td>
                                <td>{{action.targetDate | date: _dateformart.GridDateFormat}}</td>
                                <td>{{getStatusName(action.statusMappingId)}}</td>
                                <td>{{action.closureDate | date: _dateformart.GridDateFormat}}</td>
                            </tr>
                          </tbody>
                          </table>
                          
                                <a type="button" class="btn btn-secondary pull-right m-t-10" (click)="cancelDialog()"> Close </a>
                                
                                
                          
                        </p-dialog>
                
                            </div>
              
                            <!-- Mitigation Action Popup End -->               
    
</div>
</body>
</html>