<div class="card flex justify-content-center">
    <p-breadcrumb class="max-w-full" [model]="getBreadcrumbs()"></p-breadcrumb>
  </div>
    <div class="container page-title"> 
        <div class="flex-box">
            <div class="fr-flex__container">
                <div class="fr-flex__col-6">
                    <h1>User Roles Mapping <span class="number_of_records"> ({{ filteredRecordCount > -1 ? filteredRecordCount : totalRecordCount }} of {{ totalRecordCount }} Records)  </span></h1>
                </div>

                
                    <div class="fr-flex__col-6">  
                    
                      
                        <button class="btn btn-outline-primary pull-right" (click)="showfilter()"
                    [ngClass]="{'active-button': showHideFiltersControls}"> <i class="pi pi-filter"></i> </button>
                
                    <button class="btn btn-outline-primary pull-right" (click)="refreshClick()">Refresh</button>
                
              </div>
              
            
        </div>
    <div *ngIf="showHideFiltersControls">
        <!-- Filters -->
        <div class="fr-flex__col-12">
            <div class="filter-bg">
        <div class="fr-flex__container">
            <div class="fr-flex__col-3">
                <div class="fr-form__group margin-zero"> <span class="p-input-icon-left"> <i
                   class="pi pi-search"></i>
                   <input type="text" class="form-control key-search filter-text search-active" [class.search-active]="filterDetailsModel.Key !== undefined && filterDetailsModel.Key !== null && filterDetailsModel.Key !== ''"
                   [(ngModel)]="filterDetailsModel.Key"  placeholder="Search by First Name, Last Name, Email"
                   (input)="onfilter()" trimSpace /> </span> 
                </div>
             </div>
            <div class="fr-flex__col-2">
                <p-multiSelect appendTo="body" class="multiSelect-active muultiselect_header_hide" [showToggleAll]="false" panelStyleClass="custom-panel" [class.multiSelect-active]="filterDetailsModel.GroupType !== undefined && filterDetailsModel.GroupType !== null && filterDetailsModel.GroupType.length !== 0" [options]="groupTypeList" [filter]="false"
                [(ngModel)]="filterDetailsModel.GroupType" optionLabel="label" optionValue="label" placeholder="Select Group Type"
                (onChange)="onfilter()"></p-multiSelect>
             </div>
             <div class="fr-flex__col-2">
                <p-multiSelect appendTo="body" class="multiSelect-active muultiselect_header_hide" [showToggleAll]="false" panelStyleClass="custom-panel" [class.multiSelect-active]="filterDetailsModel.Group !== undefined && filterDetailsModel.Group !== null && filterDetailsModel.Group.length !== 0" [options]="groupList" [filter]="false"
                [(ngModel)]="filterDetailsModel.Group" placeholder="Select Group" optionLabel="label" optionValue="label"
                (onChange)="onfilter()"></p-multiSelect>
             </div>
             <div class="fr-flex__col-2">
                <p-multiSelect appendTo="body" class="multiSelect-active muultiselect_header_hide" [showToggleAll]="false" panelStyleClass="custom-panel" [class.multiSelect-active]="filterDetailsModel.AssignedRoles !== undefined && filterDetailsModel.AssignedRoles !== null && filterDetailsModel.AssignedRoles.length !== 0" [options]="assignedRolesList" [filter]="false"
                [(ngModel)]="filterDetailsModel.AssignedRoles" placeholder="Select Roles" optionLabel="label" optionValue="label"
                (onChange)="onfilter()"></p-multiSelect>
             </div>
              <a class="btn btn-primary-link" (click)="clearFilters()"> Clear All Filters </a>
        </div>
        </div>
        </div>
       
        
    </div>
   
    <p-table class="primeng-table-custom" [resizableColumns]="true" columnResizeMode="expand" styleClass="p-datatable-striped p-datatable-gridlines"
    [scrollable]="true"
    editMode="row"
    [value]="usersList"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Entries"
    [rowsPerPageOptions]="[10, 20, 50, 100, 200]"
     [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header">
            <tr>                
                <th id="adminURMFirstName" pSortableColumn="firstName" pResizableColumn>First Name <p-sortIcon field="firstName"></p-sortIcon></th>
                <th id="adminURMLastName" pSortableColumn="lastName" pResizableColumn>Last Name <p-sortIcon field="lastName"></p-sortIcon></th>
                <th id="adminURMEmailId" pSortableColumn="emailId" pResizableColumn>Email <p-sortIcon field="emailId"></p-sortIcon></th>
                <th id="adminURMGroupType" pSortableColumn="groupType" pResizableColumn>Group Type <p-sortIcon field="groupType"></p-sortIcon> </th>
                <th id="adminURMGroup" pSortableColumn="group" pResizableColumn>Group(s) <p-sortIcon field="group"></p-sortIcon> </th>
                <th id="adminURMAssignedRoles" pSortableColumn="assignedRoles" pResizableColumn>Assigned Role(s) <p-sortIcon field="assignedRoles"></p-sortIcon></th>
                <th id="adminIsActive" pSortableColumn="isActive" pResizableColumn>IsActive <p-sortIcon field="isActive"></p-sortIcon> </th>
                <th id="adminIsLocked" pSortableColumn="isLocked" pResizableColumn>IsLocked <p-sortIcon field="isLocked"></p-sortIcon> </th>
                <th id="adminURMUserRoleListEdit" *ngIf="editPrivilege" class="last-child-width">Edit</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user> 
            <tr>
                <td [pTooltip]="user.firstName.length > 20 ? user.firstName : ''" tooltipPosition="top"> <a class="table-active-link" (click)="ViewRoleMapping(user.id)">  {{ user.firstName }} </a> </td>
                <td [pTooltip]="user.lastName.length > 20 ? user.firstName : ''" tooltipPosition="top">{{ user.lastName }}</td>
                <td [pTooltip]="user.emailId.length > 20 ? user.emailId : ''" tooltipPosition="top">{{ user.emailId }}</td>
                <td [pTooltip]="user.groupType.length > 20 ? user.groupType : ''" tooltipPosition="top">{{ user.groupType }}</td>
                <td [pTooltip]="user.group.length > 20 ? user.group : ''" tooltipPosition="top">{{ user.group }}</td>
                <td [pTooltip]="user.assignedRoles.length > 20 ? user.assignedRoles : ''" tooltipPosition="top">{{ user.assignedRoles}}</td>

                <td>{{ user.isActive }}</td>
                <td>{{ user.isLocked }}</td>

                <td *ngIf="editPrivilege" (click)="EidtRoleMapping(user.id)" class="grid-icon-center"><span class="pi pi-pencil"></span></td>
                
            </tr>
  
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
            <td colspan="7" class="text-center"> No Records</td>
            </tr>
            </ng-template>
    </p-table>
   
</div>

   