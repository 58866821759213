<!DOCTYPE html>
<html lang="en">
<head>
   <title> Edit Accrual </title>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-Content-Type-Options" content="nosniff">
</head>
<body>
<div class="card-pad"> 
   <div class="fr-flex__container sticky-sub-header">
      <div class="fr-flex__col-6">
         <h3>Edit Draft Invoices</h3>
      </div>
      <div class="fr-flex__col-6">  
         <div class="preview_section pull-right">
            <button  [class.previous]="currentIndex !== 0"  (click)="pageChangeConfirmation(2)" [disabled]="currentIndex === 0"><i class="pi pi-arrow-up" pTooltip="Preview" tooltipPosition="top"></i></button>
            <span class="numbers_list">{{getCurrentPositionText()}}</span>
            <button [class.next]="currentIndex !== listOfDraftInvocies.length - 1" (click)="pageChangeConfirmation(1)"  [disabled]="currentIndex === listOfDraftInvocies.length - 1"><i class="pi pi-arrow-down" pTooltip="Next" tooltipPosition="top"></i></button>
            </div> 
      </div>
      </div>

      <div class="scroll-start-here-sub-tabs">    
<form [formGroup]="accrualForm"> 
   <div class="fr-flex__container m-t-20">
      <div class="fr-flex__col-4">
         <div class="fr-form__group">
            <label> Month  <em> * </em></label>
              <input type="text" class="form-control read-only" pInputText value="{{ accrualDetail.accrualDate | date:'MMMM yyyy' }}" readonly="readonly"/>
         </div>
        
      </div>
      <div class="fr-flex__col-4">
        <div class="fr-form__group">
           <label> Draft Invoices Amount   <em> * </em></label>
           <p-inputNumber formControlName="_accrualAmount" inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" class="form-control-inputNumber" type="text" [maxlength]="15" [(ngModel)]="accrualDetail.accrualAmount" [ngClass]="{'read-only-value-condication': isDisabled}" [readonly]="isDisabled"/>
           <small class="p-error" *ngIf="accrualSubmitted && accrualForm?.get('_accrualAmount')?.hasError('required')">This field is required.</small>
           <small class="p-error" *ngIf="accrualSubmitted && accrualForm?.get('_accrualAmount')?.hasError('pattern')"> Only numbers allowed and maximum 15 digits which includes 2 decimal places. </small>
           <small class="p-error" *ngIf="accrualSubmitted && accrualForm?.get('_accrualAmount')?.hasError('maxlength')">   Maximum length is 15 characters. </small>
               
        </div>
     </div>
      <div class="fr-flex__col-4">
         <div class="fr-form__group">
            <label>Status <em> * </em> </label>
            <p-dropdown appendTo="body" [filter]="true" formControlName="_statusMappingId" [options]="accrualStatusDataSource" [(ngModel)]="accrualDetail.statusMappingId" optionLabel="name" optionValue="id" placeholder="Select Status" (onChange)="onStatusChange($event)" [ngClass]="{'read-only-dropdown':isDisabled}" [readonly]="isDisabled"></p-dropdown>
            <small class="p-error" *ngIf="accrualSubmitted && accrualForm?.get('_statusMappingId')?.hasError('required')">This field is required.</small>
         </div>
      </div>
     
   </div>
  
   <div class="fr-flex__container">
    <div class="fr-flex__col-4">
      <div class="fr-form__group">
         <label> Attachment  <i  pInputText pTooltip="Only 1 Attachment allowed." tooltipPosition="top" class="pi pi-info-circle"> </i></label>
  

                    
                    <div *ngIf="this.accrualAttachment.fileName==''"> 
                     <input   type="file"  accept=".pdf, .xls, .xlsx,.doc,.docx"  formControlName="_accrualFile" class="form-control custom-file-input" id="accrualFileAttachment"  (change)="onAccrualAttachmentChange($any($event.target).files)" #fileInput style="display:none" [ngClass]="{'read-only-attachment': isDisabled}" [disabled]="isDisabled">
                     <button type="button" (click)="fileInput.click()" class="form-control width100" [disabled]="isDisabled">Upload Files</button>
                     </div>

                     <div *ngIf="this.accrualAttachment.fileName!=''">
                     <a class="attachment-active" routerLinkActive="active" [ngClass]="{'read-only-attachment': isDisabled}"> 
                        <span [pTooltip]="accrualAttachment.fileName?.length > 40 ? accrualAttachment.fileName : ''" tooltipPosition="top" (click)="downloadFile(accrualAttachment.fileName,accrualAttachment.filePath)">
                           <em> {{ accrualAttachment.fileName }}  </em>  
                        </span> 
                        <i *ngIf="!isDisabled" pInputText pTooltip="Delete" tooltipPosition="top" class="pi pi-trash" (click)="prepareDeleteFileObject()"> </i> 
                        </a>
                     </div>
  
  
      </div>
   </div>
   </div>
   <div class="fr-flex__container">
    <div class="fr-flex__col-12">
      <div class="fr-form__group">
         <label> Remarks <span class="max-length"> {{accrualDetail.remarks?.length || 0}}/300 </span> </label>
         <textarea class="form-control" formControlName="_remarks" pInputTextarea [(ngModel)]="accrualDetail.remarks" [maxlength]="300"></textarea>
      </div>
   </div>
   </div>
</form>

<div class="fr-flex__container">
   <div class="fr-flex__col-3">
      <div class="fr-form__view">
         <label> Modified By</label>
         <span  *ngIf="updatedBy !=null"> <em>  {{updatedBy}} </em></span>
         <span *ngIf="updatedBy == null || updatedBy == undefined || updatedBy == ''"> <em> -- </em></span>
      </div>
   </div>
   <div class="fr-flex__col-3" >
      <div class="fr-form__view">
         <label> Modified Date & Time </label>
         <span  *ngIf="updatedBy !=null && updatedBy != undefined && updatedBy != ''"> <em> {{accrualDetail?.updatedDate | date: _dateformart.DateTimeWithZone }} {{_dateformart.DisplayUTC}}</em></span>
         <span *ngIf="updatedBy ==null || updatedBy == undefined || updatedBy == ''"> <em> -- </em></span>
        </div>
   </div>

   <div class="fr-flex__col-3">
      <div class="fr-form__view">
         <label> Approved By</label>
         <span  *ngIf="approvedByUser !=null"> <em>  {{approvedByUser}} </em></span>
         <span *ngIf="approvedByUser == null || approvedByUser == undefined || approvedByUser == ''"> <em> -- </em></span>
      </div>
   </div>


   <div class="fr-flex__col-3">
      <div class="fr-form__view">
         <label> Approved Date & Time</label>
         <span  *ngIf="approvedByUser !=null"> <em>  {{accrualDetail?.approvedDateTime | date: _dateformart.DateTimeWithZone}} {{_dateformart.DisplayUTC}} </em></span>
         <span *ngIf="approvedByUser == null || approvedByUser == undefined || approvedByUser == ''"> <em> -- </em></span>
      </div>
   </div>



</div>
</div>
      <!-- Save & Cancel Footer buttons Start -->
      <div class="fr-flex__container footer_btns">
        <div class="fr-flex__col-12">
           <button routerLinkActive="active" [class.disabled]="isSaveBtnDisable" type="button" class="btn btn-primary btn-save pull-right"  (click)="isSaveBtnDisable ? null : saveContractAccrual()"> Save </button>
           <button type="button" class="btn btn-secondary pull-right" (click)="onCancelContractAccrual()" > Cancel </button>
     </div>
  </div>
  

  <!-- Save & Cancel Footer buttons  End -->
</div>
</body>
</html>

