<div class="card flex justify-content-center">
   <p-breadcrumb class="max-w-full" [model]="getBreadcrumbs()"></p-breadcrumb>
 </div>
 <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
<div class="container page-title"> 
   <div class="flex-box-inside-pages"> 
   <div class="fr-flex__container">
      <div class="fr-flex__col-8">
         <h1>Edit User Roles Mapping </h1>
     </div>
   </div>
</div>
      <p-tabView class="fr-tabview__header__custom"> 
         <p-tabPanel> 
            <div class="card-pad"> 
      <form [formGroup]="userRole">
      <div class="fr-flex__container"> 
      <div class="fr-flex__col-4">
         <h3>&nbsp;</h3>
      </div>
      <div class="fr-flex__col-8">  
         <div class="preview_section pull-right">
            <button  [class.previous]="currentIndex !== 0"  (click)="pageChangeConfirmation(2)" [disabled]="currentIndex === 0||buttonDisabled"><i class="pi pi-arrow-up" pTooltip="Preview" tooltipPosition="top"></i></button>
            <span class="numbers_list">{{getCurrentPositionText()}}</span>
            <button [class.next]="currentIndex !== listUserData.length - 1" (click)="pageChangeConfirmation(1)"  [disabled]="currentIndex === listUserData.length - 1||buttonDisabled"><i class="pi pi-arrow-down" pTooltip="Next" tooltipPosition="top"></i></button>
            </div> 
                        </div>
                        </div>
<div class="fr-flex__container m-t-20">
    <div class="fr-flex__col-4">
       <div class="fr-form__group">
          <label> First Name </label>
          <input type="text" formControlName="_firstName" class="form-control read-only" [(ngModel)]="_userRoles.firstName" trimSpace>
       </div>
    </div>
    <div class="fr-flex__col-4">
       <div class="fr-form__group">
          <label> Last Name </label>
          <input type="text" formControlName="_lastName" class="form-control read-only" [(ngModel)]="_userRoles.lastName" trimSpace>
       </div>
    </div>
    <div class="fr-flex__col-4">
        <div class="fr-form__group">
           <label> Email </label>
           <input type="text" formControlName="_emailId" class="form-control read-only" [(ngModel)]="_userRoles.emailId" trimSpace>
        </div>
     </div>
 </div>
 <div class="fr-flex__container">
   <div class="fr-flex__col-4">
      <div class="fr-form__group">
         <label> User Name </label>
         <input type="text" formControlName="_userName" class="form-control read-only" [(ngModel)]="_userRoles.userName" trimSpace>
      </div>
   </div>
   <div class="fr-flex__col-4">
      <div class="fr-form__group">
         <label> Group Type <em> * </em></label>
         <p-dropdown appendTo="body" formControlName="_groupType" [class.read-only-dropdown]="IsDisableGroupType" [readonly]="IsDisableGroupType" [options]="GroupTypesDataSource" placeholder="Select Group Type" [(ngModel)]="_userRoles.groupType" optionLabel="name" optionValue="id" (onChange)="OnChangeGroupType($event)"></p-dropdown><br>
         <small class="p-error"
         *ngIf="submitted && userRole?.get('_groupType')?.hasError('required')">This field is
         required.</small>
      </div>
   </div>
    <div class="fr-flex__col-4">
       <div class="fr-form__group">
          <label> Group <em> * </em>  </label>
          
          <p-multiSelect appendTo="body" formControlName="_group" [options]="GroupDataSource"
          [(ngModel)]="_userRoles.group" placeholder="Select Group(s)"
          optionValue="id" optionLabel="name" display="chip">
          <ng-template let-group pTemplate="item">
            <!-- Add the tooltip to the multi-select options -->
            <div [pTooltip]="group.name.length > 30 ? group.name : null" tooltipPosition="right">
               {{ group.name.length > 30 ? group.name.substring(0, 30) + '...' : group.name }}
            </div>
         </ng-template>
         </p-multiSelect>
          
          <small class="p-error"
          *ngIf="submitted && userRole?.get('_group')?.hasError('required')">This field is
          required.</small>
         

       </div>
    </div>
   
  
 </div>
 <div class="fr-flex__container">
   <div class="fr-flex__col-4">
      <div class="fr-form__group">
         <label> Assigned Role(s) <em> * </em></label>
         <p-multiSelect appendTo="body" formControlName="_roles" [options]="RolesFilterDataSource"
         [(ngModel)]="_userRoles.roles" placeholder="Select Assigned Role(s)"
         optionValue="id" optionLabel="name" display="chip"></p-multiSelect><br>
         <small class="p-error"
         *ngIf="submitted && userRole?.get('_roles')?.hasError('required')">This field
         is required.</small>
      </div>
   </div>
    

   <div class="fr-flex__col-4">
      <div class="fr-form__group">
      <label> IsActive </label>
      <!-- <span><em>{{isUserActive}}</em></span> -->
      <input type="text" class="form-control read-only" [value]="isUserActive" readonly>
   </div>
   </div>


   <div class="fr-flex__col-4">
      <div class="fr-form__group">
      <label> IsLocked </label>
      <!-- <span><em>{{isUserLocked}}</em></span> -->
      <input type="text" class="form-control read-only" [value]="isUserLocked" readonly>
   </div>
   </div>


 </div>
 <div class="fr-flex__container">
   <div class="fr-flex__col-12">
      <div class="fr-form__group">
         <label> Remarks <span class="max-length">
           {{_userRoles.remarks?.length}}/300 </span> </label>
         <textarea class="form-control" [maxlength]="300" formControlName="_remarks" pInputTextarea [(ngModel)]="_userRoles.remarks" trimSpace></textarea>
      </div>
   </div>
 </div>
 <div class="fr-flex__container">

   <div class="fr-flex__col-4">
      <div class="fr-form__view">
         <label> Modified By</label>
         <span  > <em> {{updatedByUserName!=''?updatedByUserName:'--'}}</em></span>

      </div>
   </div>
   <div class="fr-flex__col-4">
      <div class="fr-form__view">
         <label> Modified Date & Time </label>
         <span *ngIf="_userRoles.updatedDate!=null"> <em> {{_userRoles.updatedDate | date: _dateformart.DateTimeWithZone }} {{_dateformart.DisplayUTC}}</em></span>
         <span *ngIf="_userRoles.updatedDate==null"> <em> --</em></span>
        </div>
   </div>
</div>
</form>
 <div class="fr-flex__container footer_btns">
   <div class="fr-flex__col-12">
      <a type="button" [class.disabled]="isSaveBtnDisable" (click)="isSaveBtnDisable ? null : onUpdateUserRoles()" class="btn btn-primary btn-save pull-right"> Save </a>
      <a type="button" (click)="CancelRolePrivilege()" class="btn btn-secondary pull-right"> Cancel </a>
   </div>
</div>
</div>
</p-tabPanel>
</p-tabView>
</div>

