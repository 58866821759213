import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ContractService } from 'src/app/Contracts/Services/contract.service';
import { AccrualAttachments, CodeListValues } from 'src/app/Finance/Models/contract-accrual';
import { FinanceService } from 'src/app/Finance/Services/invoice.service';
import { LoaderService } from 'src/app/Loader/loader.service';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';

import { Dateformat } from 'src/app/shared/constants/global.constants';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { CurrencyService } from 'src/app/Reusable/currency.service';
import { FileUploadService } from 'src/app/Engagement/Services/fileupload.service';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';

@Component({
  selector: 'app-con-accruals-view',
  templateUrl: './con-accruals-view.component.html',
  styleUrls: ['./con-accruals-view.component.css']
})
export class ConAccrualsViewComponent {

  @Output() editButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelAccrualButtonClick = new EventEmitter<void>();
  accrualStatusDataSource: CodeListValues[] | undefined;

  @Input() contractId: any;
  @Input() accrualId: any;

  accrualDetail:any;
  _dateformart :any;
  selectedOption: string = 'Actions';
  editPrivilege:boolean=false;
  currentIndex: number = -1; 
  listOfDraftInvocies:any;
  constructor(private privilegeService: PrivilegeService,private route: ActivatedRoute,private router:Router,private loaderService: LoaderService,private datePipe: DatePipe,private financeService:FinanceService,private messageService: MessageService, private contractService: ContractService,private _fileService: FileUploadService, private masterDataService: SponserService,private currencyService: CurrencyService, private tabAndStateService: TabAndStateService ){
  }

  accrualAttachment:any;
  currency:any;
  accrualAttachments: AccrualAttachments[]=[];
  updatedBy:any;
  remarks:any
   ngOnInit() {
    this.bindData(this.accrualId);
    this.GetAccrualStatus(3);
    this._dateformart=Dateformat;
    this.BindPrivileges();
    this.listOfDraftInvocies=this.tabAndStateService.getDraftInvoicesData();
    this.currentIndex = this.listOfDraftInvocies.findIndex((a: { accrualDate: any; }) => a.accrualDate ==this.accrualDetail.accrualDate);
}

approvedByUser:any;
bindData(data:any)
{
  this.accrualDetail = data;
  this.remarks = this.accrualDetail.remarks ? this.accrualDetail.remarks.replace(/\n/g, "<br>") : this.accrualDetail.remarks;
  this.accrualAttachment= new AccrualAttachments();
  this.currency= this.route.snapshot.queryParamMap.get('currency');
  if(this.accrualDetail.id!=0)
  {
    if(this.accrualDetail.statusMappingId==0)  //this.accrualDetail.accrualAmount==0
    {
      this.accrualDetail.accrualAmount=null;
      this.accrualDetail.statusMappingId=null;
    } 
       
    this.accrualDetail.contractDetailId=this.contractId;
    this.updatedBy=this.privilegeService.getUserName(this.accrualDetail.updatedBy)
    this.approvedByUser= this.privilegeService.getUserName(this.accrualDetail.approvedBy);
   // if(this.accrualDetail.statusMappingId==0)
       

    if(this.accrualDetail.financeContractAccrualAttachments == null){
      this.accrualDetail.financeContractAccrualAttachments.push(this.accrualAttachment)
    }
    else if(this.accrualDetail.financeContractAccrualAttachments.length>0){
      this.accrualAttachment.fileName=this.accrualDetail.financeContractAccrualAttachments[0].fileName;
      this.accrualAttachment.filePath=this.accrualDetail.financeContractAccrualAttachments[0].filePath;
   }

  }
  else{
    this.updatedBy=null;
    this.approvedByUser=null;
    if(this.accrualDetail.statusMappingId==0){
      this.accrualDetail.statusMappingId=null;
      this.accrualDetail.accrualAmount=null;
    }
 
    this.accrualDetail.contractDetailId=this.contractId;
    if(this.accrualDetail.financeContractAccrualAttachments == null){
      this.accrualDetail.financeContractAccrualAttachments=this.accrualAttachments;
      this.accrualDetail.financeContractAccrualAttachments.push(this.accrualAttachment)
    }
   
  }
}


BindPrivileges(){
  const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
this.editPrivilege = hasPermission(Privileges.EditAccruals);
  }
GetAccrualStatus(type:number) {
  this.masterDataService.getCodeListData(type,'Accrual Status').subscribe(
    res=>{
      this.accrualStatusDataSource=res.data;
    }
  );
};

GetContractAccrualDetailById() {
  if(this.accrualDetail.id>0){
    this.financeService.GetContractAccrualDetailById(this.accrualDetail.id).subscribe(
      res=>{
        let accrualData=res.data;
      }
    );
  }
};


fileDetail:any;
async downloadFile(fileName:any,filePath:any){
 this.loaderService.showLoader();
  let fileDetail = {
    "fileName":fileName,
    "filePath":filePath
 };
 this._fileService.DownloadFile(fileDetail).subscribe(
    async res=>{
        let blobData = this._fileService.dataURItoBlob(res);
        if(blobData.size>0){
           let response = await this._fileService.downloadBase64File(blobData,fileDetail.fileName);
           this.loaderService.hideLoader();
        }
        else{
          this.loaderService.hideLoader();
          this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Sorry, unable to download the file', detail: '' });
        }
    } 
 );

}


onOptionChange(): void {//Click on Actions button navigate to edit  
  if (this.selectedOption === 'Edit') {
    let contractId= this.contractId;
  let accrualId=this.accrualDetail.id.toString();
  const data = {
    contractId:this.contractId,
    accrualId: this.accrualDetail
  };
  this.editButtonClick.emit(data);
  }    
}
BackToList()
{
  this.cancelAccrualButtonClick.emit();
}
getFormattedValue(value: number, currencyName: string): string {
  return this.currencyService.getFormattedValue(value, currencyName);
}
navigateTo(index: number): void {
  if (index >= 0 && index < this.listOfDraftInvocies.length) {
    this.accrualDetail=this.listOfDraftInvocies[index];
    this.bindData(this.accrualDetail);
    this.currentIndex=index;
  }
}
nextRecord(): void {
  this.navigateTo(this.currentIndex + 1);
}
previousRecord(): void {
  this.navigateTo(this.currentIndex - 1);
}
getCurrentPositionText(): string {
  return `${this.currentIndex + 1} of ${this.listOfDraftInvocies.length}`;
}


}
